import Select from 'react-select';
import * as S from './FrequencySelect.styles';
import React, { useCallback, useContext, useEffect } from 'react';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

export const FrequencySelect = props => {
  const { subscriptionSchedules, useSelectComponent, language } = props;

  const {
    setFrequency,
    extraObjects,
    setExtraObjects,
    frequency,
    setTubIndex,
    setCurrentTubIndex,
    currentCategory,
    setCurrentProduct,
    pageDesign
  } = useContext(FunnelContext);

  const [, setExtraObjects2] = useLocalStorage('extraObjects');

  const scheduleExtraObjects = useCallback(
    value => {
      const [{ extraObjects: extraObjs }] = subscriptionSchedules.filter(
        s => s.id === value
      );
      if (extraObjs) {
        extraObjs.map(({ key, value }) => {
          setExtraObjects(
            Object.assign(extraObjects, {
              [key]: value
            })
          );
          setExtraObjects2(
            Object.assign(extraObjects, {
              [key]: value
            })
          );
          return null;
        });
      }
    },
    [subscriptionSchedules, setExtraObjects, extraObjects, setExtraObjects2]
  );

  const updateCurrentTubIndex = useCallback(
    value => {
      const item = subscriptionSchedules.find(s => s.id === value);
      if (item.updateCurrentTubIndex) {
        const productPrefix =
          currentCategory === `onetime` ? `onetime` : `subscription`;
        setTubIndex(item.currentTubIndex);
        setCurrentTubIndex(item.currentTubIndex);
        setCurrentProduct(`${productPrefix}_${item.currentTubIndex}`);
      }
    },
    [
      subscriptionSchedules,
      setCurrentTubIndex,
      setTubIndex,
      currentCategory,
      setCurrentProduct
    ]
  );

  useEffect(() => {
    if (!extraObjects?.initDefaultSelection) {
      const defaultSelection = subscriptionSchedules.filter(
        s => s.defaultSelection
      );
      if (defaultSelection.length > 0) {
        scheduleExtraObjects(defaultSelection[0].id);
        setFrequency(defaultSelection[0].id);
      } else {
        const defaultFreq = subscriptionSchedules.filter(
          s => s.id === frequency
        );
        if (defaultFreq.length > 0) {
          scheduleExtraObjects(frequency);
        } else {
          const firstFreq = subscriptionSchedules[0] ?? null;
          if (firstFreq) {
            scheduleExtraObjects(firstFreq.id);
            setFrequency(firstFreq.id);
          }
        }
      }
      setExtraObjects(
        Object.assign(extraObjects, {
          initDefaultSelection: true
        })
      );
    }
  }, [
    frequency,
    scheduleExtraObjects,
    setFrequency,
    subscriptionSchedules,
    extraObjects,
    setExtraObjects
  ]);

  useEffect(() => {
    if (!extraObjects?.initDefaultSelection) {
      const defaultSelection = subscriptionSchedules.filter(
        s => s.defaultSelection
      );
      if (defaultSelection.length > 0) {
        updateCurrentTubIndex(defaultSelection[0].id);
      } else {
        const defaultFreq = subscriptionSchedules.filter(
          s => s.id === frequency
        );
        if (defaultFreq.length > 0) {
          updateCurrentTubIndex(frequency);
        } else {
          const firstFreq = subscriptionSchedules[0] ?? null;
          if (firstFreq) {
            updateCurrentTubIndex(firstFreq.id);
          }
        }
      }
      setExtraObjects(
        Object.assign(extraObjects, {
          initDefaultSelection: true
        })
      );
    }
  }, [
    frequency,
    subscriptionSchedules,
    updateCurrentTubIndex,
    extraObjects,
    setExtraObjects
  ]);

  const frequencyOnChange = evt => {
    setFrequency(evt.target.value);
    scheduleExtraObjects(evt.target.value);
    updateCurrentTubIndex(evt.target.value);
  };

  const handleSelectChange = ({ value }) => {
    setFrequency(value);
    scheduleExtraObjects(value);
    updateCurrentTubIndex(value);
  };

  if (subscriptionSchedules) {
    const options = [];
    subscriptionSchedules.map(item => {
      return options.push({ value: item.id, label: item.label });
    });

    const SelectContainer = () => {
      if (useSelectComponent) {
        return (
          <Select
            classNamePrefix="frequency-select"
            options={options}
            onChange={handleSelectChange}
            inputProps={{ readOnly: true }}
            isSearchable={false}
            defaultValue={options.filter(option => option.value === frequency)}
            inputId="frequency-select-input"
          />
        );
      } else {
        return (
          <select
            id="frequency-select-input"
            defaultValue={frequency}
            aria-label="Frequency"
            onBlur={frequencyOnChange}
            onChange={frequencyOnChange}
          >
            {subscriptionSchedules.map(({ id, label }) => {
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              );
            })}
          </select>
        );
      }
    };

    const langDeliveryFrequency =
      language?.deliveryFrequency ?? `Delivery Frequency`;
    return (
      <S.Frequency pageDesign={pageDesign} className="frequency">
        <S.FrequencyLabel htmlFor="frequency-select-input">
          {langDeliveryFrequency}
        </S.FrequencyLabel>
        <SelectContainer />
      </S.Frequency>
    );
  } else {
    return '';
  }
};
export default FrequencySelect;
